body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@import url("https://fonts.googleapis.com/css2?family=Archivo:wght@100;200;300;400;500;600;700;800;900&family=Hind:wght@300;400;500;600;700&family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap");

.active {
  color: #006aff !important;
}

.siq-okbtn,
.siq-trkbtn {
  background-color: white !important; /* for background color */
  color: #006aff !important; /* for text color */
}
.siq-msgbanr {
  background-color: #006aff !important;
}

/* Typography */
.section__title {
  font-size: 3rem;
  font-weight: 300;
  margin-bottom: 1.25rem;
}

.section__sub__title {
  font-size: 1.115rem;
  font-weight: 400;
}

.section__details {
  font-size: 1.25rem;
  font-weight: 200;
}

@media screen and (max-width: 1199.9px) {
  .section__title {
    font-size: 2.5rem;
  }

  .section__sub__title {
    font-size: 1.1rem;
  }

  .section__details {
    font-size: 1.15rem;
  }
}

@media screen and (max-width: 767.9px) {
  .section__title {
    font-size: 2rem;
  }

  .section__sub__title {
    font-size: 1rem;
  }

  .section__details {
    font-size: 1rem;
  }
}

@keyframes moveBorder {
  0% {
    width: 0;
    /* left: 0; */
  }
  100% {
    width: 100%;
    /* left: 0; */
  }
}
