.footer__container {
  background: #f8f9fa;
  padding: 4rem 0;
}

.footer__note {
  font-size: 1rem;
  width: 45%;
  /* margin: 2rem 0; */
  font-weight: 400;
  font-family: "Hind";
}

.links__group {
  list-style: none;
  display: flex;
  gap: 0.75rem 0;
  margin-top: 0.5rem;
}

.footerLinkItem {
  color: #120200;
  font-size: 16px;
  text-decoration: none;
  font-family: "Hind", sans-serif;
  cursor: pointer;
}

.socialIcon {
  display: flex;
  align-items: center;
  gap: 0 0.875rem;
}

.socialIcon a {
  text-decoration: none;
  color: white;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #454242;
  border-radius: 50%;
  padding: 6px;
  box-sizing: border-box;
}

@media screen and (max-width: 1199.9px) {
  .footer__note {
    width: 60%;
  }
}

@media only screen and (max-width: 767.9px) {
  .logo__img {
    width: 150px;
  }

  .footer__note {
    width: 100%;
    margin: 1rem 0 3rem;
  }

  .footerLinkItem {
    color: #120200;
    font-size: 12px;
  }

  .socialIcon a {
    color: white;
    height: 20px;
    width: 20px;
  }
}
